<template>
  <div>
    <Menu :menu="accessMenu" prefix="" />
    <transition name="page">
      <router-view />
    </transition>
  </div>
</template>

<script>
import Menu from '@/components/atoms/Menu.vue'

import { ADDEVENT_ADMIN_ROUTES } from '@/constants/routing'
import { mapGetters } from 'vuex'

export default {
  components: {
    Menu,
  },
  ADDEVENT_ADMIN_ROUTES,
  data() {
    return {
      menu: [
        { title: 'Каталог', link: ADDEVENT_ADMIN_ROUTES.CATALOG_INDEX },
        { title: 'Заказы', link: ADDEVENT_ADMIN_ROUTES.ORDERS_INDEX },
        {
          title: 'Календарь заказов',
          link: ADDEVENT_ADMIN_ROUTES.ORDERS_CALENDAR_INDEX,
        },
        {
          title: 'Блог',
          link: ADDEVENT_ADMIN_ROUTES.BLOG_INDEX,
        },
        {
          title: 'Настройки',
          link: ADDEVENT_ADMIN_ROUTES.SETTINGS_INDEX,
        },
        {
          title: 'Контент и SEO',
          link: ADDEVENT_ADMIN_ROUTES.CONTENT_INDEX,
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      filterAccessMenuList: 'filterAccessMenuList',
    }),
    accessMenu() {
      return this.filterAccessMenuList(this.menu)
    },
  },
}
</script>
